// core version + navigation, pagination modules:
import Swiper, {Navigation, EffectCoverflow} from 'swiper/core';

Swiper.use([Navigation, EffectCoverflow])

const mobileCoverflowOptions = {
    rotate: 0,
    stretch: 0,
    depth: 0,
    slideShadows: false,
}
const desktopCoverflowOptions = {
    rotate: 0,
    stretch: 0,
    depth: 300,
    slideShadows: false,
}

const coverflowSwiperElem = document.querySelector('.coverflow-swiper');

const coverflowSwiper = new Swiper(coverflowSwiperElem, {
    slidesPerView: 1,
    effect: 'coverflow',
    centeredSlides: true,
    grabCursor: true,
    speed: 400,
    spaceBetween: 20,
    coverflowEffect: mobileCoverflowOptions,
    slideToClickedSlide: true,
    initialSlide: coverflowSwiperElem ? coverflowSwiperElem.getAttribute('data-first-slide') : '',
    navigation: {
        nextEl: '.coverflow-swiper--btn--next',
        prevEl: '.coverflow-swiper--btn--prev',
    },

    breakpoints: {
        768: {
            slidesPerView: 2,
            coverflowEffect: mobileCoverflowOptions,
            spaceBetween: 20,
        },
        992: {
            slidesPerView: 2,
            coverflowEffect: desktopCoverflowOptions,
            spaceBetween: 0,
        },
        1200: {
            slidesPerView: 3,
            coverflowEffect: desktopCoverflowOptions,
            spaceBetween: 0,
        },
    }
});


coverflowSwiper.on('slideChange', function () {
    // var el = coverflowSwiper.slides[coverflowSwiper.activeIndex];
    document.getElementById('media_subscription').parentNode.style.display = 'none';
    document.getElementById('product_partnership').parentNode.style.display = 'none';
    document.getElementById('showroom_pass').parentNode.style.display = 'none';
    document.getElementById('formOffersSubscription').parentNode.parentNode.style.display = 'none';
    document.getElementById('iframeOffersSubscription').parentNode.style.display = 'none';
    
    /**
     * If we decide to back to display with slide change uncomment and remove the two lines before
     */
    // if(el.dataset.cards == 'media_subscription') {
    //     document.getElementById('media_subscription').parentNode.style.display = 'block';
    // }
    // else {
    //     document.getElementById('media_subscription').parentNode.style.display = 'none';
    // }
});
