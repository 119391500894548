// core version + navigation, pagination modules:
import Swiper, {Navigation} from 'swiper/core';

Swiper.use([Navigation])

const cardSwiper = new Swiper('.cards-swiper', {
    slidesPerView: 1,
    spaceBetween: 90,
    grabCursor: true,
    navigation: {
        nextEl: '.swiper-button--next',
        prevEl: '.swiper-button--prev',
    },
    breakpoints: {
        992: {
            slidesPerView: 2,
        },
        1200: {
            slidesPerView: 3,
        },
        1500: {
            slidesPerView: 4,
        }
    }
});

// Slider must be disabled on tablets and mobiles
const cardSwiperWrapper = document.querySelector('.cards-swiper .swiper-wrapper')
if (cardSwiperWrapper) {
    const breakWidth = 992;
    const mediaQuery = 'screen and (min-width: ' + breakWidth + 'px)';

    const toggleCardSwiper = () => {

        if (window.matchMedia(mediaQuery).matches) {
            cardSwiper.enable();
            cardSwiperWrapper.classList.remove('swiper-wrapper-off');
        } else {
            cardSwiper.disable();
            cardSwiperWrapper.classList.add('swiper-wrapper-off');
        }
    }

    // Make sure that swiper library don't interfere when disabled
    window.addEventListener('resize', () => {
        if (window.innerWidth < breakWidth) {
            cardSwiperWrapper.removeAttribute('style')
        }
    })

    // Check screen size on page load
    toggleCardSwiper()
    // Check screen size on media query break
    window.matchMedia(mediaQuery).onchange = () => {
        toggleCardSwiper()
    }
}
