window.$ = window.jQuery = require('jquery');

import './vendors/bootstrap';
import './components/navbar';
import './components/icons.font';
import './components/coverflowSwiper';
import './components/cardSwiper';
import './components/advSwiper';
import './components/mediaEncartsSwiper';
import './components/mediaCarousel';
import './components/select';
import './components/accordion';
import './components/dateRangePicker';
import './components/forms';
import './components/sticky';
import './components/clipboard';
import './components/button';