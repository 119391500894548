/* Voir les options ici :
https://github.com/rgalus/sticky-js#available-options */

import Sticky from "sticky-js";

const stickyEl = new Sticky('.sticky-top-element', {
    stickyContainer: '.sticky-container',
});

$(window).on('ajaxUpdateComplete', function () {
    stickyEl.update()
})
