// core version + navigation, pagination modules:
import Swiper, {Navigation, Autoplay} from 'swiper/core';

Swiper.use([Navigation, Autoplay])

// Pictocards swiper================================================================================================
const cardSwiper = new Swiper('.adv-swiper', {
    slidesPerView: 2,
    spaceBetween: 20,
    grabCursor: true,
    autoplay: {
        delay: 3000,
    },
    navigation: {
        nextEl: '.adv-swiper-button--next',
        prevEl: '.adv-swiper-button--prev',
    },
    breakpoints: {
        576: {
            slidesPerView: 3,
        },
        1200: {
            slidesPerView: 4,
        }
    }
});
