import 'select2/dist/js/select2';

const selectOptions = {
    theme: 'bootstrap',
    minimumResultsForSearch: 20,
    width: "style"
}

const initSelectors = () => {
    $('.js-selector').each(function () {
        // Get data-placeholder attribute and add it to select2 options
        selectOptions.placeholder = $(this).data('label');
        const selector = $(this).select2(selectOptions);

        selector.on('select2:opening select2:closing', function() {
          var $searchfield = $(this).parent().find('.select2-search__field');
          $searchfield.prop('disabled', true);
        });

        // Add class on selector when at least one result is selected
        selector.on('select2:select', function () {
            $(this).addClass('some-results-selected')
        })

        // And remove it when no selection anymore
        selector.on('select2:unselect', function () {
            if ($(this).find(':selected').length === 0) {
                $(this).removeClass('some-results-selected')
            }
        })
    })
}

initSelectors();
$(window).on('ajaxUpdateComplete', function () {
    initSelectors();
})
