let button = $('.btn-article--mask');

button.click(function(e) {
  e.stopImmediatePropagation();
  if(window.innerWidth > 1200){
    $('.login-nav-desktop').addClass('show');
    $('.login-nav-desktop--dropdown').addClass('show');
  } else {
    window.location = $(this).attr('data-href');
  }
});

var aSelector = ['.page-title-section', 'main', 'footer', '.adv-swiper-section'];
for(var i = 0; i < aSelector.length; i++){
  $(aSelector[i]).click(function () {
    $('.login-nav-desktop').removeClass('show');
    $('.login-nav-desktop--dropdown').removeClass('show');
  });
}