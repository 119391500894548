import Sticky from "sticky-js";

const navbarCollapse = $('.navbar-collapse');
const navbar = $('.navbar');
const toggler = $('.navbar-toggler');
const dropdowns = navbar.find(".dropdown:not('.login-nav-desktop')");
const dropdownsMenus = dropdowns.find('.dropdown-menu');

// Add classes to navbar and toggler button on hide / show mobile menu
navbarCollapse.on('show.bs.collapse hide.bs.collapse', function (e) {
    if (e.type === 'show') {
        navbar.addClass('is-open')
        toggler.addClass('is-active')
    }
    if (e.type === 'hide') {
        navbar.removeClass('is-open')
        toggler.removeClass('is-active')
    }
})

// Open submenus on hover instead of click
dropdowns.hover(function () {
    const dropdownMenu = $(this).children(".dropdown-menu");
    if (dropdownMenu.is(":visible")) {
        dropdownMenu.removeClass("open");
        $(this).removeClass("open");
    } else {
        dropdownMenu.addClass("open");
        $(this).addClass("open");
    }
});

// When submenus are opened on mobile, close it when resize to desktop
const mediaQuery = 'screen and (min-width: 1200px)';
window.matchMedia(mediaQuery).onchange = () => {
    if (window.matchMedia(mediaQuery).matches) {
        dropdownsMenus.removeClass("show");
    }
}

// Make navbar sticky top
const sticky = new Sticky('.navbar', {
    wrapWith: '<span class="w-100"></span>'
});

// Make sure sticky navbar is updated on resize
window.addEventListener('resize', () => {
    sticky.update();
})

// When navbar is transparent on top, we have to apply background when scrolling down in the page
if (navbar.hasClass('navbar-transparent')) {
    const toggleTransparentNavbar = () => {
        if (window.scrollY > 10) {
            navbar.removeClass('navbar-transparent')
        } else {
            navbar.addClass('navbar-transparent')
        }
    }

    toggleTransparentNavbar()
    document.addEventListener('scroll', () => {
        toggleTransparentNavbar()
    })
}



