const checkIfFilled = (elem) => {
    tmpval = elem.val();
    if (tmpval == '') {
        elem.removeClass('filled');
    } else {
        elem.addClass('filled');
    }
}

$('input, textarea').each(function () {
    checkIfFilled($(this));
});

$('body').on('blur', 'input, textarea', function () {
    checkIfFilled($(this));
});

$('.form-group--label-effect label').on('click', function () {
    $(this).parent().find('input').focus();
});

$('input[type="text"], input[type="email"], input[type="password"],textarea, select').each(function () {
    if ($(this).prop('required')) {
        $(this).parent().find('label').append(' *');
    }
});