import ClipboardJS from 'clipboard';

document.querySelectorAll('.js-clipboard').forEach((elem) => {
    const clipboard = new ClipboardJS(elem);

    clipboard.on('success', function (e) {
        const $elem = $(elem);
        $elem.tooltip({
            title: elem.getAttribute('data-clipboard-message'),
            trigger: 'manual'
        });
        $elem.tooltip('show');
        setTimeout(() => {
            $elem.tooltip('hide');
        }, 6000)
    });
})
