// Add or remove class "open" to accordion item when collapsible shows or hide

$('.accordion--item').each(function () {
    const item = $(this);
    const collapsible = $(this).find('.collapse');
    collapsible.on('show.bs.collapse hide.bs.collapse', function (e) {
        if (e.type === 'show') { item.addClass('open') }
        if (e.type === 'hide') { item.removeClass('open') }
    })
})
