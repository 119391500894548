// core version + navigation, pagination modules:
import Swiper, {Navigation} from 'swiper/core';

Swiper.use([Navigation])

// Media carousel ============================================================================================
const mediaCarousel = new Swiper('.media-carousel-swiper', {
    slidesPerView: 1,
    spaceBetween: 0,
    grabCursor: true,
    autoplay: {
        delay: 5000,
        pauseOnMouseEnter: true,
    },
    navigation: {
        nextEl: '.carousel-button--next',
        prevEl: '.carousel-button--prev',
    },
});
