// core version + navigation, pagination modules:
import Swiper from 'swiper/core';

// Pictocards swiper================================================================================================
const cardSwiper = new Swiper('.media-encarts-swiper', {
    slidesPerView: 1.5,
    spaceBetween: 20,
    grabCursor: true,
    breakpoints: {
        432: {
            slidesPerView: 2,
            spaceBetween: 30,
        },
        576: {
            slidesPerView: 2,
            spaceBetween: 30,
        },
        768: {
            slidesPerView: 3,
            spaceBetween: 30,
        },
        992: {
            slidesPerView: 4,
            spaceBetween: 30,
        },
        1200: {
            grabCursor: false,
            slidesPerView: 5,
            spaceBetween: 30,
        }
    }
});
